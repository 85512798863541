<template>
  <div>
    <div>
      <el-form>
        <!-- 标签 -->
        <el-form-item label="标签：">
          <el-button type="primary" plain style="padding: 2px" @click="showEditDialog = true"
            >编辑标签</el-button
          >
          <div>
            <el-button
              class="tag"
              type="primary"
              size="mini"
              round
              v-for="(item, index) in labelList"
              :key="index"
            >
              {{ item.labelName }} <i class="el-icon-close" @click="removeLabel(index)"></i>
            </el-button>
          </div>
        </el-form-item>
        <!-- 履历 -->
        <el-form-item label="履历填写(选填)：">
          <el-input type="textarea" v-model="resume" placeholder="请输入相关履历信息" />
        </el-form-item>
        <!-- 项目 -->
        <el-form-item label="参与过的项目：" v-show="manageId">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            height="350px"
            v-loading="loading"
          >
            <el-table-column align="center" prop="index" label="序号">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="projectName" label="项目名称" />
            <el-table-column align="center" prop="projectRole" label="项目角色">
              <template slot-scope="scope">
                {{ scope.row.projectRole | dict(dictData.projectRole) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="joinDate" label="加入项目时间">
              <template slot-scope="scope">
                {{ scope.row.joinDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="projectPredictStartDate" label="项目预计开始时间">
              <template slot-scope="scope">
                {{ scope.row.projectPredictStartDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="projectPredictEndDate" label="项目预计完成时间">
              <template slot-scope="scope">
                {{ scope.row.projectPredictEndDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="actualPredictStartDate" label="项目实际开始时间">
              <template slot-scope="scope">
                {{ scope.row.actualPredictStartDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="accomplishDate" label="项目实际完成时间">
              <template slot-scope="scope">
                {{ scope.row.accomplishDate | dateFormat }}
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div style="text-align: right">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="pageChangeHandler"
              :current-page="parameter.pageNow"
              :page-size="parameter.pageSize"
              prev-text="上一页"
              next-text="下一页"
              layout="total, prev, pager, next, slot, jumper"
              :total="total"
            >
              <span class="el-pagination__jump e_a_pagination">
                <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
                <span style="padding-top: 1px">条/页</span>
              </span>
            </el-pagination>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 编辑标签 -->
    <el-dialog
      append-to-body
      title="编辑标签"
      :visible.sync="showEditDialog"
      width="33%"
      class="tag-dialog"
    >
      <div>
        <p>已选标签：</p>
        <div>
          <el-button
            class="tag"
            type="primary"
            size="mini"
            round
            v-for="(item, index) in labelList"
            :key="index"
          >
            {{ item.labelName }} <i class="el-icon-close" @click="removeLabel(index)"></i>
          </el-button>
        </div>
      </div>
      <div>
        <div>
          <el-button
            class="tag all-tag"
            size="mini"
            round
            v-for="(item, index) in labelData"
            :key="index"
            @click="addLabel(index)"
          >
            {{ item.labelName }}
          </el-button>
        </div>
      </div>
      <div>
        <p>创建新标签：</p>
        <div style="margin-right: 30px">
          <el-input placeholder="请输入" clearable v-model="newLabel" size="medium" width="50">
            <el-button slot="append" background-color="black" @click="add">新增</el-button>
          </el-input>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  props: {
    resume: {
      type: String,
      default: '',
    },
    labelList: {
      type: Array,
      default: function () {
        return []
      },
    },
    manageId: {},
  },
  data() {
    return {
      loading: false,
      tableData: [], //项目列表
      total: 0, //标签条数
      showEditDialog: false, //编辑标签弹窗
      dictData: [], //字典
      labelData: [], //所有标签
      newLabel: '', //新增的标签名称
      parameter: {
        //搜索
        staffId: '',
        pageNow: 1,
        pageSize: 10,
      },
      total: 0,
      pageSize: 0,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  inject: ['re'],
  watch: {
    resume: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$emit('update:resume', newVal)
      },
    },
    labelList: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$emit('update:labelList', newVal)
        this.getLabel()
      },
    },
    manageId: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.parameter.staffId = newVal
          this.getData()
        }
      },
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    //获取项目角色方式字典
    this.$api.dict
      .listSysDictData('Project_Role', true)
      .then(res => {
        this.dictData.projectRole = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.getLabel()
  },
  methods: {
    // 获取项目列表
    getData() {
      this.loading = true
      this.$api.user
        .getUserDept(this.parameter)
        .then(res => {
          if (res.data) {
            this.tableData = res.data.records
            this.total = ~~res.data.total
          } else {
            this.tableData = res.data
            this.total = 0
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    //获得标签列表
    getLabel(add = false) {
      this.$api.user
        .labelList({ pageNow: 1, pageSize: 1000 })
        .then(res => {
          if (res.data == []) {
            this.labelData = res.data
          } else {
            this.labelData = res.data.records
            if (this.labelList) {
              this.labelList.forEach(element => {
                this.labelData = this.labelData.filter(item => item.id != element.id)
              })
            } else {
              this.labelList = []
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 用户新增标签
    addLabel(index) {
      this.labelList.push(this.labelData[index])
    },

    //用户移除标签
    removeLabel(index) {
      this.labelList.splice(index, 1)
    },

    // 新增标签
    add() {
      if (this.newLabel) {
        this.$api.user
          .addLabel({ labelName: this.newLabel })
          .then(res => {
            if (res.msg == '添加成功') {
              this.$message.success(res.msg)
              this.getLabel()
            } else {
              this.$message.info(res.msg + ',该标签已添加到已选标签')
              var temp = this.labelData.find(item => item.id == res.data)
              if (temp) this.labelList.push(temp)
            }
            this.newLabel = ''
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    //换页
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },

    //换页面大小
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.mainList_operation {
  margin-top: 20px;
}

.search_input {
  width: 200px;
  margin-right: 10px;
}

/deep/ .el-table .cell {
  white-space: pre-wrap; //表格换行
  padding: 6px 3px; //信息太多，缩小间距
}

.btn_danger {
  padding-left: 5px;
}

.dialog_table {
  margin: 10px 1px;
  width: 700px;
}

.tag {
  padding: 5px 9px;
  margin: 0 11px 5px 0px;
}

.all-tag {
  background-color: #d9d9d9a0;
}

/deep/ .el-dialog__body {
  padding: 10px 20px 0px 25px;
}

.tag-dialog div {
  margin-bottom: 30px;
}

/deep/.el-input-group__append {
  background-color: #409eff;
  color: #fff;
}
</style>
